/**
 *  项目的配置文件
 */

// 从html中获取配置信息
let dataset = {};
if (window.document && document.querySelector('html').dataset) {
    dataset = document.querySelector('html').dataset;
}

export const apiUrl = dataset.apiUrl || 'https://api.baiyjk.com/'//接口请求地址

export const chatUrl = dataset.chatUrl || 'wss://im.baiyjk.com'//客服地址

export const storeUrl = dataset.storeUrl || 'https://seller.baiyjk.com/';//对应的商户后台地址

// export const pcUrl = dataset.pcUrl || 'https://www.baiyjk.com/';//对应的商城地址
export const pcUrl = dataset.pcUrl || 'https://h5.baiyjk.com/';//对应的商城地址(h5前端地址)

export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** juyuan *** version-v3.1 *** date-2021-10-20 ***主版本v3.4**/


